@keyframes rota {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
.animate-rota {
  animation: rota 1s linear infinite;
}
@keyframes rotasmall {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@keyframes fade {
  0% {
    opacity: 1;
    transform: scale(0.9);
  }
  50% {
    opacity: 0.8;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(0.9);
  }
}
.animate-rotasm {
  animation: rotasmall 2s linear infinite;
}
.animate-fade {
  animation: fade 2s linear infinite;
}
