.slick-slider.slick-initialized ul.slick-dots {
  position: absolute !important;
  bottom: 15px !important;
}
@media (max-width: 768px) {
  .slick-slider.slick-initialized ul.slick-dots {
    position: absolute !important;
    bottom: 15px !important;
  }
}
.slick-dots li:first-child button:before {
  width: 23px;
  height: 23px;
  border-radius: none;
  border-right: 20px;
  font-size: 50px !important;
  content: "" !important;
  background-color: #200f06;
  clip-path: polygon(
    29% 6%,
    69% 6%,
    91% 34%,
    95% 67%,
    78% 94%,
    30% 94%,
    3% 68%,
    0% 30%
  );
  opacity: 1 !important;
}
.slick-dots li button:before {
  width: 23px;
  height: 23px;
  border-radius: none;
  border-right: 20px;
  font-size: 50px !important;
  content: "" !important;
  background-color: #200f06;
  clip-path: polygon(
    29% 5%,
    69% 6%,
    99% 30%,
    95% 75%,
    76% 99%,
    10% 98%,
    0 73%,
    0% 30%
  );
  opacity: 1 !important;
}
.slick-dots li:last-child button:before {
  width: 23px;
  height: 23px;
  border-radius: none;
  border-right: 20px;
  font-size: 50px !important;
  content: "" !important;
  background-color: #200f06;
  clip-path: polygon(
    29% 5%,
    69% 6%,
    99% 30%,
    95% 75%,
    59% 89%,
    21% 89%,
    8% 72%,
    5% 31%
  );
  opacity: 1 !important;
}
.slick-dots li:first-child.slick-active button:before {
  width: 23px;
  height: 23px;
  border-radius: none;
  border-right: 20px;
  font-size: 50px !important;
  content: "" !important;
  background-color: #fee046;
  clip-path: polygon(
    29% 6%,
    69% 6%,
    91% 34%,
    95% 67%,
    78% 94%,
    30% 94%,
    3% 68%,
    0% 30%
  );
  opacity: 1 !important;
}
.slick-dots li.slick-active button:before {
  width: 23px;
  height: 23px;
  border-radius: none;
  border-right: 20px;
  font-size: 50px !important;
  content: "" !important;
  background-color: #fee046;
  clip-path: polygon(
    29% 5%,
    69% 6%,
    99% 30%,
    95% 75%,
    76% 99%,
    10% 98%,
    0 73%,
    0% 30%
  );
  opacity: 1 !important;
}
.slick-dots li:last-child.slick-active button:before {
  width: 23px;
  height: 23px;
  border-radius: none;
  border-right: 20px;
  font-size: 50px !important;
  content: "" !important;
  background-color: #fee046;
  clip-path: polygon(
    29% 5%,
    69% 6%,
    99% 30%,
    95% 75%,
    59% 89%,
    21% 89%,
    8% 72%,
    5% 31%
  );
  opacity: 1 !important;
}
